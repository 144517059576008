export function formatCurrency (value = 0, withCurrency = true) {
  const formatted = new Intl.NumberFormat().format(value)
  return `${withCurrency ?  `Rp. ${formatted}` : formatted}`
}

export function unformatNumber(value) {
  value = (value ?? '').trim();
  const hasParentheses = value.includes('(') || value.includes(')');

  if (value === '' || value === 'NaN') {
    return '';
  }

  if (hasParentheses) {
    value = removeParentheses(value);
  }

  value = value.replace('Rp.', '').trim();
  value = value.trim().replace(/,/g, '');

  if (isNaN(Number(value))) {
    return value;
  }

  if (isNaN(parseInt(value))) {
    return '';
  }

  value = parseInt(value);
  return hasParentheses ? value * -1 : value;
}

export function removeParentheses(value) {
  return value.replace('(', '').replace(')', '').trim();
}

export function formatDatepicker (value) {
  const date = new Date(value)
  return `${date.getUTCDate()}/${date.getUTCMonth() + 1}/${date.getUTCFullYear()}`
}

export function formatDateMonthName (value) {
  const date = new Date(value)
  return `${date.getUTCDate()} ${date.toLocaleString('default', { month: 'long' })} ${date.getUTCFullYear()}`
}
